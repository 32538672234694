import * as React from 'react'

import { Link, graphql } from 'gatsby'

import Container from '../components/Container'
import IndexLayout from '../layouts'
import Page from '../components/Page'

const PageTwo = () => (
  <IndexLayout>
    <Page>
      <Container>
        <h1>debug page</h1>
        <p>Welcome to page 2</p>
        <ul>
          <li>
            <Link to="/a-markdown-page/">Show me some Markdown!</Link>
          </li>
          <li>
            <Link to="/">Take me back home.</Link>
          </li>
        </ul>
      </Container>
    </Page>
  </IndexLayout>
)

export default PageTwo

// export const query = graphql`
//   query DebugQuery {
//     site {
//       siteMetadata {
//         title
//       }
//     }
//   }
// `
